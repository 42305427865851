import { useState } from 'react'
import emailjs from 'emailjs-com'

const initialState = {
  name: '',
  email: '',
  message: '',
  submitText: 'Send Message',
  submitEnabled: true,
  submitSuccessMessage: ''
}
export const Contact = (props) => {
  const [{ name, email, message, submitText, submitEnabled, submitSuccessMessage }, setState] = useState(initialState)

  const[submitButtonText, setSubmitButtonText] = useState(submitText)
  const[submitButtonEnabled, setSubmitButtonEnabled] = useState(submitEnabled)
  const[successMessage, setSuccessMessageText] = useState(submitSuccessMessage)
  // const[contactName, setContactName] = useState(name)
  // const[contactEmail, setContactEmail] = useState(email)
  // const[contactMessage, setContactMessage] = useState(message)

  const handleChange = (e) => {
    const { name, value } = e.target
    setState((prevState) => ({ ...prevState, [name]: value }))
  }
  // original
  //const clearState = () => setState({ ...initialState })

  const clearState = () => {
    console.log('Clearing state...')
    setState(initialState)
    console.log('Cleared state...')
  }

  const changeSubmitButtonText = (text) => setSubmitButtonText(text)
  const changeSubmitButtonEnabled = (Boolean) => setSubmitButtonEnabled(Boolean)
  const changeSuccessMessageText = (text) => setSuccessMessageText(text)
  // const changeContactNameText = (text) => setContactName(text)
  // const changeContactEmailText = (text) => setContactEmail(text)
  // const changeContactMessageText = (text) => setContactMessage(text)

  function mockSendEmail() {
    return new Promise(resolve => {
      setTimeout(resolve, 3000);
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    console.log(name, email, message)
    changeSubmitButtonText("Submitting");
    changeSubmitButtonEnabled(false);

    // // For testing
    // mockSendEmail().then((result) => {
    //   changeSuccessMessageText('Your message has been sent')

    //   // todo: Not sure why clearState() is not working here. Maybe revisit when the React-Fu is stronger.
    //   //clearState();
  
    //   // todo: All these calls could go if clearState() worked.
    //   changeSubmitButtonText("Send Message");
    //   changeSubmitButtonEnabled(true)
    //   // changeContactNameText('')
    //   // changeContactEmailText('')
    //   // changeContactMessageText('')
    // });

    emailjs
      .sendForm(
        'service_kr8nb1r', 'template_lc1xtzg', e.target, 'l841DoMav85MISGkw'
      )
      .then(
        (result) => {
          console.log(result.text)
          changeSuccessMessageText('Your message has been sent')

          // todo: Not sure why clearState() is not working here. Maybe revisit when the React-Fu is stronger.
          clearState();
      
          // todo: All these calls could go if clearState() worked.
          changeSubmitButtonText("Send Message");
          changeSubmitButtonEnabled(true)
          // changeContactNameText('')
          // changeContactEmailText('')
          // changeContactMessageText('')
        },
        (error) => {
          console.log(error.text)
          changeSuccessMessageText(error.text)
        }
      )
  }
  return (
    <div>
      <div id='contact'>
        <div className='container'>
          <div className='col-md-8'>
            <div className='row'>
              <div className='section-title'>
                <h2>Get In Touch</h2>
                <p>
                  Please fill out the form below to send us an email and we will
                  get back to you as soon as possible.
                </p>
              </div>
              <form name='sentMessage' validate="true" onSubmit={handleSubmit}>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='text'
                        id='name'
                        name='name'
                        className='form-control'
                        placeholder='Name'
                        required
                        onChange={handleChange}
                        //value={contactName}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='email'
                        id='email'
                        name='email'
                        className='form-control'
                        placeholder='Email'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                </div>
                <div className='form-group'>
                  <textarea
                    name='message'
                    id='message'
                    className='form-control'
                    rows='4'
                    placeholder='Message'
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className='help-block text-danger'></p>
                </div>
                <div id='success'>{successMessage}</div>
                <button type='submit' className='btn btn-custom btn-lg' disabled={submitButtonEnabled ? false : true}>
                  {submitButtonText}
                </button>
              </form>
            </div>
          </div>
          <div className='col-md-3 col-md-offset-1 contact-info'>
            <div className='contact-item'>
              <h3>Contact Info</h3>
              <p>
                <span>
                  <i className='fa fa-map-marker'></i> Address
                </span>
                {props.data ? props.data.address : 'loading'}
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-phone'></i> Phone
                </span>{' '}
                {props.data ? props.data.phone : 'loading'}
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-envelope-o'></i> Email
                </span>{' '}
                {props.data ? props.data.email : 'loading'}
              </p>
            </div>
          </div>
          <div className='col-md-12'>
            <div className='row'>
              <div className='social'>
                <ul>
                  <li>
                    <a href={props.data ? props.data.facebook : '/'}>
                      <i className='fa fa-facebook'></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.twitter : '/'}>
                      <i className='fa fa-twitter'></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.youtube : '/'}>
                      <i className='fa fa-youtube'></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id='footer'>
        <div className='container text-center'>
          <p>
            &copy; 2022 {' '}
            <a href='http://counterpoint.software' rel='nofollow'>
              Counterpoint Software
            </a>
          </p>
        </div>
      </div>
    </div>
  )
}
